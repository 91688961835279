<script setup lang="ts">
const props = defineProps<{
  defaultHeight?: number;
}>();

const handleEnter = (element: Element) => {
  const targetElement = element as HTMLElement;
  const width = getComputedStyle(targetElement).width;

  targetElement.style.cssText = `
    width: ${width};
    position: absolute;
    visibility: hidden;
    height: auto;
  `;

  const height = getComputedStyle(targetElement).height;

  targetElement.style.cssText = `
    width: '';
    position: '';
    visibility: '';
    height: 0;
  `;

  getComputedStyle(targetElement);

  requestAnimationFrame(() => {
    if (props.defaultHeight === undefined) {
      targetElement.style.height = height;
    } else if (targetElement.scrollHeight > props.defaultHeight) {
      targetElement.style.height = props.defaultHeight + 'px';
    } else {
      targetElement.style.height = height;
    }
  });
};

const handleAfterEnter = (element: Element) => {
  const targetElement = element as HTMLElement;

  if (props.defaultHeight === undefined) {
    targetElement.style.height = 'auto';
  } else if (targetElement.scrollHeight > props.defaultHeight) {
    targetElement.style.height = props.defaultHeight + 'px';
  } else {
    targetElement.style.height = 'auto';
  }
};

const handleLeave = (element: Element) => {
  const height = getComputedStyle(element).height;
  const targetElement = element as HTMLElement;

  targetElement.style.height = height;

  getComputedStyle(targetElement);

  requestAnimationFrame(() => {
    targetElement.style.height = '0';
  });
};
</script>

<template>
  <transition
    name="expand"
    @enter="handleEnter"
    @after-enter="handleAfterEnter"
    @leave="handleLeave"
  >
    <slot></slot>
  </transition>
</template>

<style scoped lang="postcss">
* {
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
  will-change: height;
}
</style>
